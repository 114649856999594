import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { GET_BOARDS_DATA_BASE_NAME } from './constant'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type BoardsState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialBoardsState: BoardsState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchBoardsApiData = createAsyncThunk(
  `${GET_BOARDS_DATA_BASE_NAME}/fetchBoardsApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `lookups/get-boards-type/`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getBoardsSlice = createSlice({
  name: `${GET_BOARDS_DATA_BASE_NAME}/getBoards`,
  initialState: initialBoardsState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBoardsApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchBoardsApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchBoardsApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default getBoardsSlice.reducer
