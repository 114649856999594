import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import appConfig from '@/configs/app.config'
import { SLICE_RESERVATION_DATA } from './constant'
import { clearDataIfUnauthorized } from '@/utils/accessToken'

export type GetCalendarReservationsState = {
  loading: boolean
  apiData: any
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const calendarReservationInitialState: GetCalendarReservationsState = {
  loading: false,
  apiData: null,
  status: 'idle',
  error: null
}

interface GetCalendarReservationsProps {
  propertyId: number
  pricingPlanId: number
  startDate?: string
  endDate?: string
}

export const fetchGetCalendarReservations = createAsyncThunk(
  `${SLICE_RESERVATION_DATA}/GetCalendarReservations`,
  async (
    {
      propertyId,
      pricingPlanId,
      startDate,
      endDate
    }: GetCalendarReservationsProps,
    thunkAPI
  ) => {
    let url = `reservation/get-reservation-for-calendar/?property_id=${propertyId}&pricing_plan_id=${pricingPlanId}`

    if (startDate && endDate)
      url += `&from_date=${startDate}&to_date=${endDate}`

    const config = {
      url,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix
    }

    return await clearDataIfUnauthorized(config, thunkAPI)
  }
)

export const getCalendarReservationsSlice = createSlice({
  name: `${SLICE_RESERVATION_DATA}/getReservationData`,
  initialState: calendarReservationInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetCalendarReservations.pending, state => {
        state.status = 'loading'
        state.loading = true
      })
      .addCase(fetchGetCalendarReservations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
        state.loading = false
      })
      .addCase(fetchGetCalendarReservations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
        state.loading = false
      })
  }
})

export default getCalendarReservationsSlice.reducer
