import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import dayjs from 'dayjs'

import appConfig from '@/configs/app.config'
import { GET_LOST_AND_FOUND } from './constants'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type LostAndFoundState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialLostAndFoundState: LostAndFoundState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchLostAndFoundReportData = createAsyncThunk(
  `${GET_LOST_AND_FOUND}/fetchLostAndFoundReportData`,
  async (
    {
      propId,
      date,
      roomType,
      lost_or_found
    }: {
      propId: any
      date?: any
      roomType?: string | null
      lost_or_found?: string | null
    },
    thunkAPI
  ) => {
    const accessToken = getAccessToken(thunkAPI)

    let url = `report/lost-found-report/?property_id=${propId}`
    if (date?.[1]) {
      url += `&date_range=${dayjs(new Date(date?.[0])).format(
        'YYYY-MM-DD'
      )}/${dayjs(new Date(date?.[1])).format('YYYY-MM-DD')}`
    }

    if (roomType) {
      url += `&room_type=${roomType}`
    }

    if (lost_or_found) {
      url += `&lost_or_found=${lost_or_found}`
    }

    const config = {
      url: `${url}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const LostAndFoundSlice = createSlice({
  name: `${GET_LOST_AND_FOUND}/getLostAndFound`,
  initialState: initialLostAndFoundState,
  reducers: {
    setCurrentLostAndFoundRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLostAndFoundReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchLostAndFoundReportData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchLostAndFoundReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentLostAndFoundRouteKey } = LostAndFoundSlice.actions

export default LostAndFoundSlice.reducer
