import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_GETDOCUMENT_TYPE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetDocTypeState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialDocTypeState: GetDocTypeState = {
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetDocTypeApiData = createAsyncThunk(
  `${SLICE_GETDOCUMENT_TYPE}/fetchGetDocTypeApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `lookups/get-travel-document-type/`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetTravelDocumentTypeSlice = createSlice({
  name: `${SLICE_GETDOCUMENT_TYPE}/getDocType`,
  initialState: initialDocTypeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetDocTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetDocTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetDocTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default GetTravelDocumentTypeSlice.reducer
