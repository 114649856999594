import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_BOOKING_ENGINE_GUEST } from './constant'
import appConfig from '@/configs/app.config'
import { clearDataIfUnauthorized } from '@/utils/accessToken'

export type GetBookingEngineGuestState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetBookingEngineGuestState: GetBookingEngineGuestState = {
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetBookingEngineGuestApiData = createAsyncThunk(
  `${GET_BOOKING_ENGINE_GUEST}/fetchGetBookingEngineGuestApiData`,
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState()
    const token = state?.reservation?.guestUser?.guestUser?.token

    const config = {
      url: 'guest-auth/user/',
      method: 'get',
      baseURL: appConfig.reservationApiPrefix
    }

    return await clearDataIfUnauthorized(config, thunkAPI, token, 'Token')
  }
)

export const GetBookingEngineGuestSlice = createSlice({
  name: `${GET_BOOKING_ENGINE_GUEST}/getBookingEngineGuest`,
  initialState: initialGetBookingEngineGuestState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetBookingEngineGuestApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetBookingEngineGuestApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetBookingEngineGuestApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default GetBookingEngineGuestSlice.reducer
