/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 24/10/2024, 14:37:25
Modified: 06/11/2024, 09:37:59

Description:
  This file contains the useUserIn hook.
*/

import { useNavigate } from 'react-router-dom'

import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import {
  fetchApiDashboardData,
  fetchPropertyApiData,
  setUser,
  useAppDispatch
} from '@/store'
import { setSelectedProperty } from '@/store/slices/properties/selectedProperty'
import useQuery from '@/utils/hooks/useQuery'
import { fetchCurrentSubApiData } from '@/store/slices/Payments'

const useUserIn = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const dispatch = useAppDispatch()

  const handleUserIn = async (user: any) => {
    if (!user) return

    const propRes = await dispatch(
      fetchPropertyApiData({
        accountId: user?.account,
        token: user?.token?.access
      })
    )

    // NOTE: The User is being set after getting property to avoid unnecessary redirects

    if (propRes?.payload?.data?.length > 0) {
      const selectedProperty = propRes?.payload?.data?.[0]

      dispatch(setUser(user))
      dispatch(setSelectedProperty(selectedProperty))

      await dispatch(fetchApiDashboardData({}))
      await dispatch(fetchCurrentSubApiData())
      // TODO: call permission api

      const redirectUrl = query.get(REDIRECT_URL_KEY)
      navigate(redirectUrl ?? appConfig.authenticatedEntryPath)
    } else {
      dispatch(setUser(user))
      navigate('/property')
    }

    localStorage.removeItem('userId')
  }

  return { handleUserIn }
}

export default useUserIn
