import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_LANGUAGE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type languageState = {
  currentRoutePermKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialLanguageState: languageState = {
  currentRoutePermKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchApiLanguageData = createAsyncThunk(
  `${SLICE_LANGUAGE_NAME}/fetchApiLanguageData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: 'account/get-language/',
      method: 'get',
      baseURL: appConfig.authApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const languageSlice = createSlice({
  name: `${SLICE_LANGUAGE_NAME}/perms`,
  initialState: initialLanguageState,
  reducers: {
    setLanguageRoutePermKey: (state, action: PayloadAction<string>) => {
      state.currentRoutePermKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiLanguageData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiLanguageData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiLanguageData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setLanguageRoutePermKey } = languageSlice.actions
export default languageSlice.reducer
