import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_PROPERTY_USER } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyUserState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyUserState: PropertyUserState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropertyUserApiData = createAsyncThunk(
  `${SLICE_PROPERTY_USER}/fetchPropertyApiData`,
  async (property_id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    let url = `property/get-all-user/`
    if (property_id) {
      url = `${url}?property_id=${property_id}`
    }
    const config = {
      url: url,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const propertyUserSlice = createSlice({
  name: `${SLICE_PROPERTY_USER}/propertyUser`,
  initialState: initialPropertyUserState,
  reducers: {
    setCurrentPropertUserRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyUserApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropertyUserApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropertyUserApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentPropertUserRouteKey } = propertyUserSlice.actions

export default propertyUserSlice.reducer
