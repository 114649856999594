import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_GETPEOPLE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetPeopleState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetPeopleState: GetPeopleState = {
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetPeopleApiData = createAsyncThunk(
  `${SLICE_GETPEOPLE}/fetchGetPeopleApiData`,
  async (p_id: number, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `reservation/get-guest/?property_id=${p_id}`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getPeopleSlice = createSlice({
  name: `${SLICE_GETPEOPLE}/getPeople`,
  initialState: initialGetPeopleState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetPeopleApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetPeopleApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetPeopleApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default getPeopleSlice.reducer
