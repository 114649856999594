import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { SLICE_GET_INVOICES } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type getInvoicesState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialGetInvoicesState: getInvoicesState = {
  apiData: [],
  status: 'idle',
  error: null
}

export const fetchInvoicesApiData = createAsyncThunk(
  `${SLICE_GET_INVOICES}/getInvoices`,
  async (dataParam: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    let conditionalUrl = 'invoice/get-invoices/?'

    const urlArray = []
    for (const [key, val] of Object.entries(dataParam)) {
      if (
        key === 'issued_date__range' &&
        (val as any)?.[0] !== 'Invalid Date' &&
        (val as any)?.[1] !== 'Invalid Date'
      ) {
        urlArray.push(`${key}=${(val as any)?.[0]},${(val as any)?.[1]}`)
      }

      if (val !== '' && val !== undefined && key !== 'issued_date__range') {
        urlArray.push(`${key}=${val}`)
      }
    }

    if (urlArray?.length > 0) {
      conditionalUrl += urlArray.join('&')
    }

    const config = {
      url: conditionalUrl,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetInvoicesSlices = createSlice({
  name: `${SLICE_GET_INVOICES}/getInvoices`,
  initialState: initialGetInvoicesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchInvoicesApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchInvoicesApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchInvoicesApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default GetInvoicesSlices.reducer
