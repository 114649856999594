import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import axios from 'axios'

const ApiPropertyService = {
  async fetchData(param: {
    url: string
    data?: object | string
    method: string
    token?: string | null | undefined
    content_type?: any
  }) {
    const config = {
      url: `${param?.url}`,
      method: `${param?.method}`,
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        ...(param?.content_type && {
          'content-type': 'application/json'
        }),
        ...(param?.token && {
          Authorization: `Bearer ${param.token}`
        })
      },
      data: param.data
    }
    const response = await axios(config)
    return response
  }
}
export default ApiPropertyService
