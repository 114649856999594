import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PROMOCODE_BOOKING_ENGINE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'

export type GetPromocodeForBookingEngineState = {
  apiData: any[] | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetPromocodeForBookingEngineState: GetPromocodeForBookingEngineState =
  {
    apiData: [],
    status: 'idle',
    error: null
  }
// Define an async thunk for fetching API data
export const fetchGetPromocodeForBookingEngineApiData = createAsyncThunk(
  `${PROMOCODE_BOOKING_ENGINE}/fetchGetPromocodeForBookingEngineApiData`,
  async ({
    property_id,
    promocode,
    checkinDate,
    checkoutDate
  }: {
    property_id: number
    promocode: string
    checkinDate: string
    checkoutDate: string
  }) => {
    const config = {
      url: `booking-engine/promocode/?property_id=${property_id}&promo_code=${promocode}&checkin_date=${checkinDate}&checkout_date=${checkoutDate}`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json'
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetPromocodeForBookingEngineSlice = createSlice({
  name: `${PROMOCODE_BOOKING_ENGINE}/promocodeBookingEngine`,
  initialState: initialGetPromocodeForBookingEngineState,
  reducers: {
    clearCurrentPromocodeForBookingEngine: state => {
      state.apiData = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetPromocodeForBookingEngineApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchGetPromocodeForBookingEngineApiData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(
        fetchGetPromocodeForBookingEngineApiData.rejected,
        (state, action) => {
          state.status = 'failed'
          state.error = action.error.message ?? null
        }
      )
  }
})

export const { clearCurrentPromocodeForBookingEngine } =
  GetPromocodeForBookingEngineSlice.actions

export default GetPromocodeForBookingEngineSlice.reducer
