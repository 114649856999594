import { combineReducers } from '@reduxjs/toolkit'
import salesChannelData, { GetSalesChannelState } from './GetSalesChannelSlice'
import getMealType, { GetMealTypeState } from './GetMealType'
import guestStatusData, { GetGuestStatusState } from './GetGuestStatusSlice'
import getDiscountType, { GetDiscountTypeState } from './DiscountTypeSlice'
import getTravelDoc, { GetDocTypeState } from './GetTravelDocumentTypeSlice'
import getPaymentType, { GetPaymentTypeState } from './GetPaymentType'
import reservationType, {
  GetReservationTypeState
} from './GetRestrationTypeSlice'
import getCompany, { GetCompanyState } from './GetCompanySlice'
import getPeople, { GetPeopleState } from './GetPeopleSlice'
import getBoardsType, { BoardsTypeState } from './GetBoardsSlice'
import getCityTaxUsage, { GetCityTaxUsageState } from './GetCityTaxUsage'
import getCityTax, { GetCityTaxState } from './GetCityTaxSlice'
import getBoardMeals, { BoardsMealsTypeState } from './GetBoardsMealsSlice'
import getUnitRooms, { GetUnitRoomsState } from './GetUnitRooms'
import getInvoiceType, { InvoiceTypeState } from './GeyInvoiceType'
import getInvoices, { getInvoicesState } from './GetInvoicesSlice'
import getCustomTax, { GetCustomTaxState } from './GetCustomTaxSlice'
import getNoneBookedRooms, {
  GetNoneBookedState
} from './GetNoneBookedRoomsSlice'
import getBookingEngine, {
  GetBookingEngineState
} from './GetBookingEngineDataSlice'
import getPromocode, { GetPromocodeState } from './GetPromoCodeSlice'

import getSingleInvoice, { getSingleInvoiceState } from './GetInvoiceSingle'

import getBookingsData, {
  GetBookingEngineReservationState
} from './GetBookingEngineReservationSlice'

import getBookingsDataPropertiesWithLocation, {
  GetBookinEnginePropertiesWithLocationState
} from './GetBookingEnginePropertiesWithLocationSlice'
import getBookingEngineGuest, {
  GetBookingEngineGuestState
} from './GetBookingEngineGuestSlice'
import guestUser, {
  guestUserState
} from '@/store/slices/Reservation/BookingEngineGuestUserSlice'
import promocodeForBookingEngine, {
  GetPromocodeForBookingEngineState
} from '@/store/slices/Reservation/GetPromocodeForBookingEngineSlice'
import getReservationDetail, {
  GetReservationDetailState
} from '@/store/slices/Reservation/GetReservationDetailSlice'
import getHouseKeepingReport, {
  HouseKeepingState
} from '@/store/slices/Reservation/reportSlices/HouseKeepingSlice'
import getCountryRevenueReport, {
  countryRevenueReportState
} from '@/store/slices/Reservation/reportSlices/CountryRevenueSlice'
import getMonthlyRevenueReport, {
  monthlyRevenueReportState
} from '@/store/slices/Reservation/reportSlices/MonthlyRevenueSlice'
import getCompanyRevenueReport, {
  companyRevenueReportState
} from '@/store/slices/Reservation/reportSlices/CompanyRevenueSlice'
import getPaceReport, {
  PaceReportsState
} from '@/store/slices/Reservation/GetPaceReportsSlice'
import calendarReservations, {
  GetCalendarReservationsState
} from './GetCalendarReservations'
import unitPrice, { GetUnitPriceState } from './GetUnitPrice'

const reducer = combineReducers({
  getCompanyRevenueReport,
  getHouseKeepingReport,
  getCountryRevenueReport,
  getMonthlyRevenueReport,
  reservationType,
  getMealType,
  salesChannelData,
  calendarReservations,
  unitPrice,
  guestStatusData,
  getDiscountType,
  getCompany,
  getPeople,
  getTravelDoc,
  getPaymentType,
  getBoardsType,
  getCityTaxUsage,
  getCityTax,
  getBoardMeals,
  getUnitRooms,
  getCustomTax,
  getNoneBookedRooms,
  getInvoiceType,
  getInvoices,
  getBookingEngine,
  getPromocode,
  getSingleInvoice,
  getBookingsData,
  getBookingsDataPropertiesWithLocation,
  getBookingEngineGuest,
  guestUser,
  promocodeForBookingEngine,
  // getInvoicesForReservation,
  getReservationDetail,
  getPaceReport
})
export type ReservationState = {
  getCompanyRevenueReport: companyRevenueReportState
  getCountryRevenueReport: countryRevenueReportState
  getMonthlyRevenueReport: monthlyRevenueReportState
  getHouseKeepingReport: HouseKeepingState
  reservationType: GetReservationTypeState
  salesChannelData: GetSalesChannelState
  mealType: GetMealTypeState
  calendarReservations: GetCalendarReservationsState
  unitPrice: GetUnitPriceState
  guestStatusData: GetGuestStatusState
  getDiscountType: GetDiscountTypeState
  getPaymentType: GetPaymentTypeState
  getCompany: GetCompanyState
  getPeople: GetPeopleState
  getTravelDoc: GetDocTypeState
  getBoardsType: BoardsTypeState
  getMealType: GetMealTypeState
  getCityTaxUsage: GetCityTaxUsageState
  getCityTax: GetCityTaxState
  getUnitRooms: GetUnitRoomsState
  getBoardMeals: BoardsMealsTypeState
  getCustomTax: GetCustomTaxState
  getNoneBookedRooms: GetNoneBookedState
  getInvoiceType: InvoiceTypeState
  getInvoices: getInvoicesState
  getBookingEngine: GetBookingEngineState
  getPromocode: GetPromocodeState
  getSingleInvoice: getSingleInvoiceState
  getBookingsData: GetBookingEngineReservationState
  getBookingsDataPropertiesWithLocation: GetBookinEnginePropertiesWithLocationState
  getBookingEngineGuest: GetBookingEngineGuestState
  guestUser: guestUserState
  promocodeForBookingEngine: GetPromocodeForBookingEngineState
  // getInvoicesForReservation:GetInvoicesForReservationState
  getReservationDetail: GetReservationDetailState
  getPaceReport: PaceReportsState
}
export * from './GetRestrationTypeSlice'
export * from './GetSalesChannelSlice'
export * from './GetMealType'
export * from './GetGuestStatusSlice'
export * from './DiscountTypeSlice'
export * from './GetCompanySlice'
export * from './GetPeopleSlice'
export * from './GetTravelDocumentTypeSlice'
export * from './GetPaymentType'
export * from './GetBoardsSlice'
export * from './GetMealType'
export * from './GetCityTaxUsage'
export * from './GetCityTaxSlice'
export * from './GetBoardsMealsSlice'
export * from './GetUnitRooms'
export * from './GetCustomTaxSlice'
export * from './GetNoneBookedRoomsSlice'
export * from './GeyInvoiceType'
export * from './GetInvoicesSlice'
export * from './GetBookingEngineDataSlice'
export * from './GetPromoCodeSlice'
export * from './GetInvoiceSingle'
export * from './GetBookingEngineReservationSlice'
export * from './GetBookingEnginePropertiesWithLocationSlice'
export * from './GetBookingEngineGuestSlice'
export * from './BookingEngineGuestUserSlice'
export * from './GetPromocodeForBookingEngineSlice'
export * from './GetReservationDetailSlice'
export * from './reportSlices/HouseKeepingSlice'
export * from './reportSlices/MonthlyRevenueSlice'
export * from './reportSlices/CountryRevenueSlice'
export * from './reportSlices/CompanyRevenueSlice'
export * from './GetPaceReportsSlice'
export * from './GetUnitPrice'
export * from './GetCalendarReservations'

export default reducer
