import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_ROLE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type roleState = {
  currentRouteRoleKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRoleState: roleState = {
  currentRouteRoleKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchApiRoleData = createAsyncThunk(
  `${SLICE_ROLE_NAME}/fetchRoleApiData`,
  async (property_Id: number, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `auth/get-role/?property_id=${property_Id}`,
      method: 'get',
      baseURL: appConfig.authApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const roleSlice = createSlice({
  name: `${SLICE_ROLE_NAME}/role`,
  initialState: initialRoleState,
  reducers: {
    setCurrentRouteRoleKey: (state, action: PayloadAction<string>) => {
      state.currentRouteRoleKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiRoleData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiRoleData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiRoleData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRouteRoleKey } = roleSlice.actions
export default roleSlice.reducer
