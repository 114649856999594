import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_DISCOUNT_TYPE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetDiscountTypeState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialDiscountTypeState: GetDiscountTypeState = {
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetDiscountTypeApiData = createAsyncThunk(
  `${SLICE_DISCOUNT_TYPE}/fetchGetDiscountTypeApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `lookups/get-discount-type/`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getDiscountTypeSlice = createSlice({
  name: `${SLICE_DISCOUNT_TYPE}/getdiscountType`,
  initialState: initialDiscountTypeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetDiscountTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetDiscountTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetDiscountTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export default getDiscountTypeSlice.reducer
