import { createSlice, createSelector } from '@reduxjs/toolkit'
import { SLICE_SELECTED_PROPERTY_BASE_NAME } from './constants'

export type selectedPropertyState = {
  property: any
}

const initialState: selectedPropertyState = {
  property: null
}

const selectedPropertySlice = createSlice({
  name: `${SLICE_SELECTED_PROPERTY_BASE_NAME}/selectedProperty`,
  initialState,
  reducers: {
    setSelectedProperty(state, action) {
      state.property = action.payload
    }
  }
})

/**
 * Selector to retrieve the selected property or the first from the properties array.
 * If no property is selected and the properties array is empty, returns null.
 *
 * @param state - The Redux store state.
 * @returns The selected property or the first from the properties array, or null if none exist.
 */
export const selectSelectedProperty = createSelector(
  (state: { selectedProperty: selectedPropertyState; properties: any }) => ({
    selectedProperty: state?.selectedProperty?.property,
    properties: state?.properties?.propertyReduce?.apiData?.data
  }),
  ({ selectedProperty, properties }) => {
    if (selectedProperty) return selectedProperty
    if (properties?.length) return properties[0]
    return null
  }
)

export const { setSelectedProperty } = selectedPropertySlice.actions
export default selectedPropertySlice.reducer
