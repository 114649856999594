import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ROOM_NFC_KEY } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type RoomNFCKeyState = {
  currentRoomNFCKeyRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialRoomNFCKeyState: RoomNFCKeyState = {
  currentRoomNFCKeyRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetNFCKeyApiData = createAsyncThunk(
  `${GET_ROOM_NFC_KEY}/getRoomNfcKey`,
  async (uid: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-room-key/?uid=${uid}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)

    return response.data
  }
)
export const GetNFCCardKeySlice = createSlice({
  name: `${GET_ROOM_NFC_KEY}/getRoomNfcKey`,
  initialState: initialRoomNFCKeyState,
  reducers: {
    setCurrentRoomNFCKeyRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRoomNFCKeyRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetNFCKeyApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetNFCKeyApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetNFCKeyApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setCurrentRoomNFCKeyRouteKey } = GetNFCCardKeySlice.actions
export default GetNFCCardKeySlice.reducer
