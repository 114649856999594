import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_UNIT_TYPE_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type UnitTypeState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialUnitTypeState: UnitTypeState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchUnitTypeApiData = createAsyncThunk(
  `${SLICE_UNIT_TYPE_BASE_NAME}/fetchPropertyApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: 'lookups/get-unit-type/',
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const unitTypeSlice = createSlice({
  name: `${SLICE_UNIT_TYPE_BASE_NAME}/unitType`,
  initialState: initialUnitTypeState,
  reducers: {
    setCurrentUnitTypeRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUnitTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchUnitTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchUnitTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentUnitTypeRouteKey } = unitTypeSlice.actions

export default unitTypeSlice.reducer
