import { createSlice } from '@reduxjs/toolkit'
import { BOOKING_ENGINE_GUEST } from '@/store/slices/Reservation/constant'

export type guestUserState = {
  guestUser: any
  authority: any
}
const initialState: guestUserState = {
  guestUser: null,
  authority: null
}

const guestUserSlice = createSlice({
  name: `${BOOKING_ENGINE_GUEST}/bookingEngineGuest`,
  initialState,
  reducers: {
    setGuestUser(state, action) {
      state.guestUser = action.payload
    }
  }
})
export const { setGuestUser } = guestUserSlice.actions
export default guestUserSlice.reducer
