import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_ALL_ROOMS_DATA_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { clearDataIfUnauthorized } from '@/utils/accessToken'

export type GetAllRoomsDataState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRoomsDataState: GetAllRoomsDataState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchAllRoomsDataApiData = createAsyncThunk(
  `${GET_ALL_ROOMS_DATA_BASE_NAME}/getAllRooms`,
  async (id: any, thunkAPI) => {
    const config = {
      url: `room/rooms-for-property/?property_id=${id}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix
    }

    return await clearDataIfUnauthorized(config, thunkAPI)
  }
)

export const getRoomsDataSlice = createSlice({
  name: `${GET_ALL_ROOMS_DATA_BASE_NAME}/getAllRooms`,
  initialState: initialRoomsDataState,
  reducers: {
    setRoomsDataRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllRoomsDataApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAllRoomsDataApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchAllRoomsDataApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setRoomsDataRouteKey } = getRoomsDataSlice.actions

export default getRoomsDataSlice.reducer
