import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_PROPERTY_ROOM_NUMBER_FLOOR_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyRoomNumberForFloorState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyRoomNumberForFloorState: PropertyRoomNumberForFloorState =
  {
    currentRouteKey: '',
    apiData: [],
    status: 'idle',
    error: null
  }

// Define an async thunk for fetching API data
export const fetchPropertyRoomNumberForFloorApiData = createAsyncThunk(
  `${SLICE_PROPERTY_ROOM_NUMBER_FLOOR_BASE_NAME}/propertyRoomNumberFloor`,
  async (roomState: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-room-number-for-floor/?property_id=${roomState}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const propertyRoomNumberFloorSlice = createSlice({
  name: `${SLICE_PROPERTY_ROOM_NUMBER_FLOOR_BASE_NAME}/propertyRoomNumberFloor`,
  initialState: initialPropertyRoomNumberForFloorState,
  reducers: {
    setCurrentPropertRoomNumberForFloorRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyRoomNumberForFloorApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchPropertyRoomNumberForFloorApiData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(
        fetchPropertyRoomNumberForFloorApiData.rejected,
        (state, action) => {
          state.status = 'failed'
          state.error = action.error.message || null
        }
      )
  }
})

export const { setCurrentPropertRoomNumberForFloorRouteKey } =
  propertyRoomNumberFloorSlice.actions

export default propertyRoomNumberFloorSlice.reducer
