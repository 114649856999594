import axios from 'axios'

import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'

const ApiService = {
  async fetchData(param: {
    url: string
    data: object | string | null
    method: string
    token?: string | null
    content_type?: any
    channex_api_key?: any
    channex?: any
  }) {
    const config = {
      url: `${param?.url}`,
      method: `${param?.method}`,
      baseURL: appConfig.authApiPrefix,

      headers: {
        'accept-language': getLocale(),
        ...(param?.content_type
          ? {
              'content-type': 'application/json'
            }
          : {
              'content-type': 'multipart/form-data'
            }),
        ...(param?.channex
          ? {
              'user-api-key': param?.channex_api_key
            }
          : {
              ...(param?.token && {
                Authorization: `Bearer ${param.token}`
              })
            })
        // ...(!param?.token && param?.channex_api_key && {
        //   'user-api-key': param?.channex_api_key
        // }),
        // ...(!param?.channex_api_key && param?.token && {
        //   Authorization: `Bearer ${param.token}`
        // })
      },
      data: param.data
    }
    const response = await axios(config)
    return response
  }
  // fetchData<Response = unknown, Request = Record<string, unknown>>(
  //     param: AxiosRequestConfig<Request>
  // ) {
  //     return new Promise<AxiosResponse<Response>>((resolve, reject) => {
  //         BaseService(param)
  //             .then((response: AxiosResponse<Response>) => {
  //                 resolve(response)
  //             })
  //             .catch((errors: AxiosError) => {
  //                 // console.log(errors)
  //                 reject(errors)
  //             })
  //     })
  // },
}

export default ApiService
