import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_PROPERTY_ROOM_NUMBER_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyRoomNumberState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyRoomNumberState: PropertyRoomNumberState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropertyRoomNumberApiData = createAsyncThunk(
  `${SLICE_PROPERTY_ROOM_NUMBER_BASE_NAME}/fetchPropertyRoomNumberApiData`,
  async (roomNumberId: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-room-number/?unit_info=${roomNumberId}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const propertyRoomNumberSlice = createSlice({
  name: `${SLICE_PROPERTY_ROOM_NUMBER_BASE_NAME}/propertyRoomNumber`,
  initialState: initialPropertyRoomNumberState,
  reducers: {
    setCurrentPropertRoomNumberRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyRoomNumberApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropertyRoomNumberApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropertyRoomNumberApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentPropertRoomNumberRouteKey } =
  propertyRoomNumberSlice.actions

export default propertyRoomNumberSlice.reducer
