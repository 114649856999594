import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_MEMBER_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type memberState = {
  currentRouteRoleKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialMemberState: memberState = {
  currentRouteRoleKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchApiMembersData = createAsyncThunk(
  `${SLICE_MEMBER_NAME}/fetchApiMembersData`,
  async (role_id: number, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `auth/get-members/?role=${role_id}`,
      method: 'get',
      baseURL: appConfig.authApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getMembersSlice = createSlice({
  name: `${SLICE_MEMBER_NAME}/member`,
  initialState: initialMemberState,
  reducers: {
    setCurrentRouteMemberKey: (state, action: PayloadAction<string>) => {
      state.currentRouteRoleKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiMembersData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiMembersData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiMembersData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setCurrentRouteMemberKey } = getMembersSlice.actions
export default getMembersSlice.reducer
