import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ROOMS_NUMBERS } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type RoomNumbersState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRoomNumbersState: RoomNumbersState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchRoomNumbersApiData = createAsyncThunk(
  `${GET_ROOMS_NUMBERS}/RoomForProperty`,
  async (property_id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-rooms-number-for-property/?property_id=${property_id}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const RoomNumbersSlice = createSlice({
  name: `${GET_ROOMS_NUMBERS}/RoomNumbers`,
  initialState: initialRoomNumbersState,
  reducers: {
    setCurrentRoomNumbersRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoomNumbersApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchRoomNumbersApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchRoomNumbersApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRoomNumbersRouteKey } = RoomNumbersSlice.actions
export default RoomNumbersSlice.reducer
