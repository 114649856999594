import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_SEC_PERM_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type sectionState = {
  currentRouteRoleKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialSectionState: sectionState = {
  currentRouteRoleKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchApiPermSectionData = createAsyncThunk(
  `${SLICE_SEC_PERM_NAME}/fetchApiPermSectionData`,
  async (role_Id: number, thunkAPI) => {
    if (!role_Id) return

    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `auth/get-role-permission/?role=${role_Id}`,
      method: 'get',
      baseURL: appConfig.authApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const sectionPermSlice = createSlice({
  name: `${SLICE_SEC_PERM_NAME}/section`,
  initialState: initialSectionState,
  reducers: {
    setCurrentRouteSecKey: (state, action: PayloadAction<string>) => {
      state.currentRouteRoleKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiPermSectionData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiPermSectionData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiPermSectionData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRouteSecKey } = sectionPermSlice.actions
export default sectionPermSlice.reducer
