import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_UNIT_DATA_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getAccessToken } from '@/utils/accessToken'
import { getLocale } from '@/utils/locale'
import axios from 'axios'

export type UnitDataState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialUnitDataState: UnitDataState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchUnitDataApiData = createAsyncThunk(
  `${GET_UNIT_DATA_BASE_NAME}/fetchUnitDataApiData`,
  async (
    { id, amenity, noToken }: { id: any; amenity?: boolean; noToken?: boolean },
    thunkAPI
  ) => {
    if (!id) return
    const accessToken = noToken ? null : await getAccessToken(thunkAPI)

    const config = {
      url: `room/get-property-room/?property_id=${id}${
        amenity ? `&amenities=${amenity}` : ''
      }`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'content-type': 'application/json',
        'accept-language': getLocale(),
        ...(noToken ? {} : { Authorization: `Bearer ${accessToken}` })
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getUnitDataSlice = createSlice({
  name: `${GET_UNIT_DATA_BASE_NAME}/getUnitData`,
  initialState: initialUnitDataState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUnitDataApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchUnitDataApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchUnitDataApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export default getUnitDataSlice.reducer
