import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { GET_MAINTENANCE } from './constants'
import dayjs from 'dayjs'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type MaintenanceReportsState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialMaintenanceReportsState: MaintenanceReportsState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchMaintenanceReportsReportData = createAsyncThunk(
  `${GET_MAINTENANCE}/fetchMaintenanceReportsReportData`,
  async (
    {
      propId,
      date,
      roomType
    }: {
      propId: any
      date?: any
      roomType?: string | null
    },
    thunkAPI
  ) => {
    const accessToken = getAccessToken(thunkAPI)

    let url = `report/get-maintenance-report/?property_id=${propId}`
    if (date?.[1]) {
      url += `&date_range=${dayjs(new Date(date?.[0])).format(
        'YYYY-MM-DD'
      )}/${dayjs(new Date(date?.[1])).format('YYYY-MM-DD')}`
    }

    if (roomType) {
      url += `&room_type=${roomType}`
    }

    const config = {
      url: `${url}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const MaintenanceReportsSlice = createSlice({
  name: `${GET_MAINTENANCE}/getMaintenance`,
  initialState: initialMaintenanceReportsState,
  reducers: {
    setCurrentMaintenanceReportsRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMaintenanceReportsReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchMaintenanceReportsReportData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchMaintenanceReportsReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentMaintenanceReportsRouteKey } =
  MaintenanceReportsSlice.actions

export default MaintenanceReportsSlice.reducer
