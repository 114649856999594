import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { COMPANY_REVENUE_REPORT } from '../constant'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type companyRevenueReportState = {
  currentCompanyRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialCompanyRevenueReportState: companyRevenueReportState = {
  currentCompanyRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchCompanyRevenueReportData = createAsyncThunk(
  `${COMPANY_REVENUE_REPORT}/fetchCompanyRevenueReportData`,
  async (propId: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `reports/get-companies-revenue-report/?property_id=${propId}`,
      method: 'get',
      baseURL: appConfig.reservationApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const CompanyRevenueSlice = createSlice({
  name: `${COMPANY_REVENUE_REPORT}/getCompanyRevenueReport`,
  initialState: initialCompanyRevenueReportState,
  reducers: {
    setCurrentGetCompanyRevenueReportRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentCompanyRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompanyRevenueReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchCompanyRevenueReportData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchCompanyRevenueReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetCompanyRevenueReportRouteKey } =
  CompanyRevenueSlice.actions

export default CompanyRevenueSlice.reducer
