import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_BOOKING_ENGINE_PROPERTIES } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'

export type GetBookinEnginePropertiesWithLocationState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetBookinEnginePropertiesWithLocationState: GetBookinEnginePropertiesWithLocationState =
  {
    apiData: [],
    status: 'idle',
    error: null
  }
// Define an async thunk for fetching API data
export const fetchGetBookinEnginePropertiesWithLocationTypeApiData =
  createAsyncThunk(
    `${GET_BOOKING_ENGINE_PROPERTIES}/fetchGetBookinEnginePropertiesWithLocationTypeApiData`,
    async (prop_code: any) => {
      const config = {
        url: `property/properties-for-booking-engine/?property_code=${prop_code}`,
        method: 'get',
        baseURL: appConfig.propertyApiPrefix,
        headers: {
          'accept-language': getLocale(),
          'content-type': 'application/json'
        }
      }

      const response = await axios(config)
      return response.data
    }
  )

export const GetBookinEnginePropertiesWithLocationSlice = createSlice({
  name: `${GET_BOOKING_ENGINE_PROPERTIES}/getBookingEngineProperties`,
  initialState: initialGetBookinEnginePropertiesWithLocationState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        fetchGetBookinEnginePropertiesWithLocationTypeApiData.pending,
        state => {
          state.status = 'loading'
        }
      )
      .addCase(
        fetchGetBookinEnginePropertiesWithLocationTypeApiData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(
        fetchGetBookinEnginePropertiesWithLocationTypeApiData.rejected,
        (state, action) => {
          state.status = 'failed'
          state.error = action.error.message || null
        }
      )
  }
})

export default GetBookinEnginePropertiesWithLocationSlice.reducer
