/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 21/08/2024, 09:23:50
Modified: 06/11/2024, 09:27:10

Description:
  This file contains the `useAuth` hook, which provides authentication-related functions and state management for user sessions. It handles various authentication processes such as signing in, signing out, signing up, verifying OTP, and managing user roles and permissions. The hook also interacts with the Redux store to manage user and property data, ensuring seamless navigation and session persistence.
*/

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import localStorage from 'redux-persist/es/storage'

import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useAppSelector, useAppDispatch } from '@/store'
import { fetchPropertyApiData } from '@/store/slices/properties'
import useQuery from './useQuery'
import useUserIn from './useUserIn'
import { clearStore } from '../accessToken'
import {
  addPhoneNumber,
  apiAddRole,
  apiChangePassword,
  apiContactUs,
  apiDeleteMember,
  apiEditMember,
  apiEditRole,
  apiEditRolePermission,
  apiSignIn,
  apiSignOut,
  apiSignUp,
  resendOtpApiCall,
  sendEmailInvoice,
  verifyOtpApiCall
} from '@/services/AuthService'
import {
  DeleteMember,
  EditMember,
  SignInCredential,
  SignOutToken,
  SignUpCredential,
  addPhone,
  addRole,
  changePasswordType,
  editRole,
  resendOtp,
  verifyOtp
} from '@/@types/auth'

type Status = 'success' | 'failed'

let isFetched = false

const useAuth = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const query = useQuery()
  const { handleUserIn } = useUserIn()

  const user = useAppSelector(state => state.auth?.user?.user)
  const properties = useAppSelector(
    (state: any) => state?.properties?.propertyReduce?.apiData?.data
  )
  const selectedProperty = useAppSelector(
    (state: any) => state?.selectedProperty?.property
  )

  useEffect(() => {
    const getProperties = async () => {
      if (user?.account && !isFetched) {
        isFetched = true
        await dispatch(fetchPropertyApiData(user?.account))
      }
    }

    getProperties()
  }, [])

  const signIn = async (
    values: SignInCredential
  ): Promise<
    | {
        status: Status
        data?: any
        message: string
      }
    | undefined
  > => {
    try {
      const resp = await apiSignIn(values)

      const user = resp?.data?.data

      if (user) {
        handleUserIn(user)

        return {
          status: 'success',
          data: resp?.data,
          message: ''
        }
      }
    } catch (errors: any) {
      if (errors?.response?.status === 422) {
        localStorage.setItem('userId', errors?.response?.data?.data?.id)
        navigate('/add-phone-number')
      }
      return {
        status: 'failed',
        data: (errors as AxiosError<{ meta: any }>).response?.data,
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const addOtpNumber = async (values: addPhone) => {
    try {
      const resp = await addPhoneNumber({
        phone_number: values.phone_number?.includes('+')
          ? values.phone_number
          : `+${values.phone_number}`
      })

      if (resp.data) {
        navigate('/verify-otp?type=phone')
        return {
          data: resp.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        data: (errors as AxiosError<{ meta: any }>).response?.data,
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const verifyOtpCode = async (values: verifyOtp) => {
    try {
      const resp = await verifyOtpApiCall(values)

      const user = resp?.data?.data

      if (user) {
        handleUserIn(user)

        return {
          status: 'success',
          data: resp?.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        data: (errors as AxiosError<{ meta: any }>).response?.data,
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const resendOtp = async (values: resendOtp) => {
    try {
      const resp = await resendOtpApiCall(values)

      return resp.data
    } catch (errors: any) {
      return {
        status: 'failed',
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const signUp = async (values: SignUpCredential) => {
    try {
      const resp = await apiSignUp(values)

      if (resp.data) {
        localStorage.setItem('userId', resp.data.data.id)

        const redirectUrl = query.get(REDIRECT_URL_KEY)
        navigate(redirectUrl ?? '/add-phone-number')
        return {
          status: 'success',
          data: resp.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const signOut = async (values: SignOutToken) => {
    try {
      await apiSignOut(values)
    } catch (err) {
      console.log('SIGN_OUT_ERROR', err)
    }

    await clearStore(dispatch)
  }

  const changePassword = async (values: changePasswordType) => {
    try {
      const resp = await apiChangePassword(values)

      if (resp.data) {
        return {
          status: 'success',
          data: resp.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const addRoles = async (values: addRole) => {
    try {
      const resp = await apiAddRole(values)
      if (resp.data) {
        return {
          status: 'success',
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      }
    }
  }

  const editRolePermission = async (values: any) => {
    try {
      const resp = await apiEditRolePermission(values)
      if (resp.data) {
        return {
          status: 'success',
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message:
          errors?.response?.data?.message ||
          errors?.response?.data?.meta?.message ||
          errors.toString()
      }
    }
  }

  const editRoles = async (values: editRole) => {
    try {
      const resp = await apiEditRole(values)
      if (resp.data) {
        return {
          status: 'success',
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      }
    }
  }

  const editMember = async (values: EditMember) => {
    try {
      const resp = await apiEditMember(values)
      if (resp.data) {
        return {
          status: 'success',
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      }
    }
  }

  const deleteMember = async (values: DeleteMember) => {
    try {
      const resp = await apiDeleteMember(values)
      if (resp.data) {
        return {
          status: 'success',
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      }
    }
  }

  const sendEmail = async (data: any) => {
    try {
      const resp = await sendEmailInvoice(data)
      if (resp.data) {
        return {
          status: 'success',
          data: resp.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  const ContactUs = async (values: any) => {
    try {
      const resp = await apiContactUs(values)
      if (resp.data) {
        return {
          status: 'success',
          data: resp.data,
          message: ''
        }
      }
    } catch (errors: any) {
      return {
        status: 'failed',
        message: (errors as AxiosError<{ meta: any }>).response?.data?.meta
          ?.message
      }
    }
  }

  return {
    authenticated: Boolean(user),
    propertyAuthenticated: Boolean(selectedProperty || properties?.length > 0),
    signIn,
    signUp,
    signOut,
    addOtpNumber,
    verifyOtpCode,
    resendOtp,
    addRoles,
    editRoles,
    editRolePermission,
    editMember,
    changePassword,
    deleteMember,
    sendEmail,
    ContactUs
  }
}

export default useAuth
