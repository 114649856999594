import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_GET_NONE_BOOKED_ROOMS } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetNoneBookedState = {
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetNonBookedRoomeState: GetNoneBookedState = {
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetNoneBookedRoomApiData = createAsyncThunk(
  `${SLICE_GET_NONE_BOOKED_ROOMS}/fetchGetNoneBookedRoomApiData`,
  async (
    {
      id,
      checkInDate,
      checkOutDate,
      noToken
    }: {
      id: number
      checkInDate: string
      checkOutDate: string
      noToken?: boolean
    },
    thunkAPI
  ) => {
    const accessToken = noToken ? null : await getAccessToken(thunkAPI)

    const config = {
      url: `room/get-rooms-number-for-reservation/?unit_id=${id}&date=${checkInDate}/${checkOutDate}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        ...(noToken ? {} : { Authorization: `Bearer ${accessToken}` })
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getNoneBookedRoomsSlice = createSlice({
  name: `${SLICE_GET_NONE_BOOKED_ROOMS}/getNoneBookedRooms`,
  initialState: initialGetNonBookedRoomeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGetNoneBookedRoomApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetNoneBookedRoomApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetNoneBookedRoomApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export default getNoneBookedRoomsSlice.reducer
