import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { SLICE_PROPERTY_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { clearDataIfUnauthorized } from '@/utils/accessToken'
import { setSelectedProperty } from './selectedProperty'

export type PropertyState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyState: PropertyState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropertyApiData = createAsyncThunk(
  `${SLICE_PROPERTY_BASE_NAME}/fetchPropertyApiData`,
  async (props: any, thunkAPI) => {
    const token = props?.token
    const accountId =
      typeof props === 'number' || typeof props === 'string'
        ? props
        : props?.accountId
    if (!accountId) return

    const config = {
      url: `property/get-user-property/?account_id=${accountId}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix
    }

    const onSuccess = (res: any) => {
      const state: any = thunkAPI.getState()
      const selectedProperty = state?.selectedProperty?.property

      if (res?.data?.meta?.status_code === 200) {
        const propertiesRes = res?.data?.data || []

        if (propertiesRes.length > 0) {
          const updatedSelectedProperty = selectedProperty?.id
            ? propertiesRes.find((it: any) => it.id === selectedProperty?.id)
            : propertiesRes?.[0]

          updatedSelectedProperty &&
            thunkAPI.dispatch(setSelectedProperty(updatedSelectedProperty))
        }
      }

      return res
    }

    return await clearDataIfUnauthorized(
      config,
      thunkAPI,
      token,
      'Bearer',
      onSuccess
    )
  }
)

export const propertySlice = createSlice({
  name: `${SLICE_PROPERTY_BASE_NAME}/users`,
  initialState: initialPropertyState,
  reducers: {
    setCurrentPropertRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropertyApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropertyApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentPropertRouteKey } = propertySlice.actions

export default propertySlice.reducer
