import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GET_PRICING_PLAN_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getAccessToken } from '@/utils/accessToken'
import { getLocale } from '@/utils/locale'
import axios from 'axios'

export type PricingPlanState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPricingPlanState: PricingPlanState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPricingPlanApiData = createAsyncThunk(
  `${GET_PRICING_PLAN_BASE_NAME}/fetchPricingPlanApiData`,
  async ({ id, noToken }: { id: any; noToken?: boolean }, thunkAPI) => {
    if (!id) return
    const accessToken = noToken ? null : await getAccessToken(thunkAPI)

    const config = {
      url: `policy/get-price-policy/?property_id=${id}`,
      method: 'get',
      baseURL: appConfig.propertyApiPrefix,
      headers: {
        'content-type': 'application/json',
        'accept-language': getLocale(),
        ...(noToken ? {} : { Authorization: `Bearer ${accessToken}` })
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getPricingPlanSlice = createSlice({
  name: `${GET_PRICING_PLAN_BASE_NAME}/users`,
  initialState: initialPricingPlanState,
  reducers: {
    setCurrentGetPricingPlanRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPricingPlanApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPricingPlanApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPricingPlanApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export const { setCurrentGetPricingPlanRouteKey } = getPricingPlanSlice.actions

export default getPricingPlanSlice.reducer
