import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { SLICE_DASHBOARD_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { clearDataIfUnauthorized } from '@/utils/accessToken'

export type dashboardState = {
  currentRouteRoleKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialDashboardState: dashboardState = {
  currentRouteRoleKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

type DashboardApi = {
  startDate?: string
  endDate?: string
}

// Define an async thunk for fetching API data
export const fetchApiDashboardData = createAsyncThunk(
  `${SLICE_DASHBOARD_NAME}/dashboard`,
  async (
    { startDate, endDate }: DashboardApi = {} as DashboardApi,
    thunkAPI
  ) => {
    const state: any = thunkAPI.getState()
    const selectedProperty = state?.selectedProperty?.property

    if (!selectedProperty?.id) return

    const durationParams =
      startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : ''
    const url = `auth/dashboard/?property_id=${selectedProperty.id}${durationParams}`

    const config = { url, method: 'get', baseURL: appConfig.authApiPrefix }

    return await clearDataIfUnauthorized(config, thunkAPI)
  }
)
export const dashboardSlice = createSlice({
  name: `${SLICE_DASHBOARD_NAME}/dashboard`,
  initialState: initialDashboardState,
  reducers: {
    setCurrentRouteDashboardKey: (state, action: PayloadAction<string>) => {
      state.currentRouteRoleKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiDashboardData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiDashboardData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiDashboardData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRouteDashboardKey } = dashboardSlice.actions
export default dashboardSlice.reducer
